import Dexie, { Table } from "dexie";
import { QuestionState } from "./ts/types";

export class GuesserDexie extends Dexie {
    // QuestionStates is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    QuestionStates!: Table<QuestionState>;

    constructor() {
        super('AniGuesser');
        this.version(1).stores({
            QuestionStates: "questionId"
        });
    }
}

export const db = new GuesserDexie();
