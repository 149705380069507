import { ButtonProps } from "../../ts/types";
import "./Button.scss";

export default function Button({ disabled, onClick, active, text, extraStyle, icon } : ButtonProps) {
    const buttonClasses = [];
    const textClasses = [];
    buttonClasses.push("common-button");
    textClasses.push("common-button__text");

    if (active) {
        buttonClasses.push("common-button--active");
    }

    if (extraStyle) {
        buttonClasses.push(`common-button--${extraStyle}`);
    }

    if (icon) {
        textClasses.push(`common-button__text--${icon}-icon`);
    }

    return (
        <button
            className={buttonClasses.join(' ')}
            onClick={onClick}
            disabled={disabled}
        >
            {text && <div className={textClasses.join(' ')}>{text}</div>}
        </button>
    )
}
