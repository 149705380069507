import { DropDownListItemProps } from "../../ts/types";
import { useEffect, useRef, KeyboardEvent } from "react";
import "./DropDownListItem.scss";

export function DropDownListItem({
    item,
    selectItem,
    focused,
} : DropDownListItemProps) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (focused) {
            ref.current?.focus();
        }
    }, [focused]);

    return (
        <div
            className="dropdownlist__item"
            tabIndex={focused ? 0 : -1}
            ref={ref}
            onClick={() => selectItem(item)}
            onKeyDown={dropDownItemKeyDownHandler}
        >
            {item.name}
        </div>
    )

    function dropDownItemKeyDownHandler({ key }: KeyboardEvent<HTMLDivElement>) {
        if (key === "Enter") {
            selectItem(item);
        }
    }
}
