import { useState } from "react";
import { ShareButtonProps } from "../../ts/types";
import Button from "../Button/Button";
import "./ShareButton.scss";

export default function ShareButton({
    gameData
} : ShareButtonProps) {
    const [showCopied, setShowCopied] = useState<boolean>(false);

    return <Button
        disabled={false}
        active={false}
        text={showCopied ? "Copied!" : "Share result"}
        onClick={shareResultButtonClickHandler}
        extraStyle={"primary"}
        icon={"share"}
    />

    async function shareResultButtonClickHandler() {
        if (!showCopied) {
            setShowCopied(true);

            setTimeout(() => {
                setShowCopied(false)
            }, 3000);
        }

        let progressSquares = '';

        for (let i = 0; i < gameData.question.hints.length; i++) {
            if (i < gameData.questionState!.guessIds.length - 1) {
                progressSquares += "🟥";
            } else {
                progressSquares += gameData.questionState!.solved ? "🟩" : "🟥";
            }
        }

        const textToCopy = `#AniGuess #${gameData.question.id}\n\n${progressSquares}\n\nhttps://aniguess.me`
        navigator.clipboard.writeText(textToCopy);
    }
}
