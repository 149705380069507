import { AnswerResultProps } from "../../ts/types";
import "./AnswerResult.scss";

export default function AnswerResult({
    success,
    correctAnswer
} : AnswerResultProps) {
    const headingClasses = [];
    headingClasses.push("answer-result__heading");
    headingClasses.push(
        success
            ? "answer-result__heading--correct"
            : "answer-result__heading--wrong"
    );

    return (
        <div className="answer-result">
            <h1 className={headingClasses.join(' ')}>
                { success ? "SOLVED" : "FAILED" }
            </h1>
            <div className="answer-result__correct-answer">
                { correctAnswer && `Correct answer: ${correctAnswer}` }
            </div>
        </div>
    )
}
