import { useState } from "react";
import { HintImageProps } from "../../ts/types";
import "./HintImage.scss";
import Modal from "../Modal/Modal";

export default function HintImage({
    src,
    hintText
} : HintImageProps) {
    const [imageExpanded, setImageExpanded] = useState(false);

    const image = <div className="hint-image-container">
        { hintText && <div className="hint-image__text">{hintText}</div> }
        <img
            className="hint-image__current"
            src={src}
            alt=""
            onClick={toggleImageExpand}
        />
    </div>

    return imageExpanded
        ? <Modal
            content={image}
            closeModal={toggleImageExpand}
        />
        : image;

    function toggleImageExpand() {
        setImageExpanded(!imageExpanded);
    }
}
