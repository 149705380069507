import { ModalProps } from "../../ts/types";
import "./Modal.scss";

export default function Modal({
    content,
    closeModal,
} : ModalProps) {
    function wrapperClick(event : React.MouseEvent<HTMLElement>) {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    }

    return <div className="modal-wrapper" onClick={wrapperClick}>
        {content}
    </div>
}
