import { GuessListProps } from "../../ts/types";
import { nanoid } from "nanoid";
import "./GuessList.scss";

export default function GuessList({
        guesses,
        correctAnswerId,
    } : GuessListProps
) {
    return <>
        <h2 className="guess-list__heading">Your guesses:</h2>
        <ul className="guess-list">
            {
                guesses.map(anime =>
                    <li
                        className={`${
                            anime.id === correctAnswerId
                                ? "guess-list__answer--correct"
                                : "guess-list__answer--wrong"
                        }`}
                        key={nanoid(10)}
                    >
                        {anime.name}
                    </li>
                )
            }
        </ul>
    </>;
}
