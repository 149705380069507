import { DropDownListProps } from "../../ts/types";
import "./DropDownList.scss"
import { useImperativeHandle, useState, KeyboardEvent, forwardRef, useRef } from "react";
import { DropDownListItem } from "./DropDownListItem";
import { nanoid } from "nanoid";

const DropDownList = forwardRef(
    (
        {
            itemsList,
            selectItem,
            continueInput,
        } : DropDownListProps
        , ref
    ) => {
        const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null);

        const dropDownRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => {
            return {
                focusFirst() {
                    setFocusedItemIndex(0);
                },

                focusLast() {
                    setFocusedItemIndex(itemsList.length - 1);
                }
            };
        }, [itemsList]);

        return (
            <div
                className="dropdownlist"
                onKeyDown={dropDownKeyDownHandler}
                onKeyUp={dropDownKeyUpHandler}
                onBlur={dropDownOnBlurHandler}
                ref={dropDownRef}
            >
                {
                    itemsList.map(
                        (item, index) =>
                            <DropDownListItem
                                item={item}
                                key={nanoid(10)}
                                selectItem={selectItem}
                                focused={index === focusedItemIndex}
                            />
                    )
                }
            </div>
        )

        function dropDownKeyDownHandler(event: KeyboardEvent<HTMLDivElement>) {
            switch (event.key) {
                case "ArrowUp":
                    setFocusedItemIndex((itemsList.length + focusedItemIndex! - 1) % itemsList.length);
                    break;
                case "ArrowDown":
                    setFocusedItemIndex((itemsList.length + focusedItemIndex! + 1) % itemsList.length)
                    break;
                default:
                    return;
            }

            event.preventDefault();
        }

        function dropDownKeyUpHandler({ key }: KeyboardEvent<HTMLDivElement>) {
            if (key.length === 1 || key === "Backspace") {
                continueInput(key);
            };
        }

        function dropDownOnBlurHandler() {
            dropDownRef.current?.scrollTo({ top: 0, behavior: "auto" });
            setFocusedItemIndex(null);
        }
    }
)

export default DropDownList;
