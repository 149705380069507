import { useState } from "react";
import { HeaderProps } from "../../ts/types";
import "./Header.scss";
import InfoModal from "../InfoModal/InfoModal";

export default function Header({
    backButtonAction,
} : HeaderProps) {
    const [showModal, setShowModal] = useState(false);

    function toggleInfoModal() {
        setShowModal(!showModal)
    }

    return <div className="header">
        { backButtonAction &&
            <button className="header__button back-button" onClick={backButtonAction}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 298 511.93">
                    <path fill="white" d="M285.77 441c16.24 16.17 16.32 42.46.15 58.7-16.16 16.24-42.45 16.32-58.69.16l-215-214.47c-16.24-16.16-16.32-42.45-.15-58.69L227.23 12.08c16.24-16.17 42.53-16.09 58.69.15 16.17 16.24 16.09 42.54-.15 58.7l-185.5 185.04L285.77 441z"/>
                </svg>
            </button>
        }
        <span className="header__title">AniGuess</span>
        <button onClick={toggleInfoModal} className="header__button header__right-button">Click here to SUGGEST an anime or HIRE ME</button>
        { showModal &&
            <InfoModal
                closeModal={toggleInfoModal}
            />
        }
    </div>
}
