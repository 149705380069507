import { ChangeEvent, useState, KeyboardEvent, useRef } from "react";
import { Anime, AnswerInputProps, DropDownListFocusHadle } from "../../ts/types";
import Button from "../Button/Button";
import "./AnswerInput.scss";
import DropDownList from "../DropDownList/DropDownList";

export default function AnswerInput({
        onSubmit,
        optionsList
    } : AnswerInputProps
) {
    const [optionsToShow, setOptionsToShow] = useState<Array<Anime>>([]);
    const [inputText, setInputText] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number>(0);

    const dropdownRef = useRef<DropDownListFocusHadle>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    function filterOptions(query: string) {
        if (!query || query.length < 3) {
            setOptionsToShow([]);
        } else {
            setOptionsToShow(optionsList.filter(hint => hint.name.toLowerCase().includes(query.toLowerCase())));
        }
    }

    function inputOnInputHandler({ target }: ChangeEvent<HTMLInputElement>) {
        setInputText(target.value);
        setSelectedId(0);

        filterOptions(target.value);
    }

    function inputKeyDownHandler(event: KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
            case "ArrowUp":
                dropdownRef.current?.focusLast();
                break;
            case "ArrowDown":
                dropdownRef.current?.focusFirst();
                break;
            case "Enter":
                submitCurrentAnswer();
                break;
            default:
                return;
        }

        event.preventDefault();
    }

    function submitCurrentAnswer() {
        if (selectedId !== 0) {
            setInputText('');
            setSelectedId(0);

            onSubmit(
                {
                    id: selectedId,
                    name: inputText,
                }
            );
        }
    }

    function handleItemSelected(anime: Anime) {
        setInputText(anime.name);
        setSelectedId(anime.id);

        inputRef.current?.focus();
        filterOptions("");
    }

    function dropDownContinueInput(newInput : string) {
        const newText = newInput === "Backspace"
            ? inputText.slice(0, inputText.length - 1)
            : inputText + newInput;

        setInputText(newText);

        filterOptions(newText);

        inputRef.current?.focus();
    }

    return (
        <div className="answer-input">
            <div className="answer-input__textfield-container">
                <input
                    autoFocus
                    className="answer-input__textfield"
                    value={inputText}
                    onChange={inputOnInputHandler}
                    onKeyDown={inputKeyDownHandler}
                    placeholder="Start typing"
                    ref={inputRef}
                />
                <div className="answer-input__dropdown-container">
                    <DropDownList
                        itemsList={optionsToShow}
                        selectItem={handleItemSelected}
                        continueInput={dropDownContinueInput}
                        ref={dropdownRef}
                    />
                </div>
            </div>
            <div className="answer-input__submit-button">
                <Button
                    disabled={selectedId === 0}
                    onClick={submitCurrentAnswer}
                    active={false}
                    text={"Check"}
                />
            </div>
        </div>
    )
}
