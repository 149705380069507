import { InfoModalProps } from "../../ts/types"
import Modal from "../Modal/Modal"
import "./InfoModal.scss"

export default function InfoModal({
    closeModal,
} : InfoModalProps) {
    const infoDialog = (
        <div className="info-modal">
            <div className="info-modal__textarea">
                <a className="email-address" href="mailto:guesseranime@gmail.com">guesseranime@gmail.com</a>
                <br /><br />
                <i>I am currently seeking employment opportunities. If you believe I can contribute to your project, kindly reach out to me via email.</i>
                <br /><br />
                If you want to <b>suggest an anime</b>, send your screenshots to the <b>address above</b>.
                Make sure to tell me your nickname if you want to be credited.
                <br /><br />
                This project is inspired by <a href="https://guessthe.game">GuessTheGame</a>
            </div>
            <button className="info-modal__bottom-button" onClick={closeModal}>Close</button>
        </div>
    )

    return <Modal
        content={infoDialog}
        closeModal={closeModal}
    />
}
