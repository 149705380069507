import "./App.scss";
import { useEffect, useState } from "react";
import Game from "./components/Game/Game";
import GameList from "./components/GameList/GameList";
import { Anime, GameData, Question, QuestionState } from "./ts/types";
import { db } from "./db";
import { isEqual } from "lodash";
import Header from "./components/Header/Header";


export default function App() {
    const [selectedGame, setSelectedGame] = useState<GameData | undefined>();
    const [games, setGames] = useState<Array<GameData>>([]);
    const [animeList, setAnimeList] = useState<Array<Anime>>([]);

    useEffect(() => {
        (async () => {
            const collator = new Intl.Collator("en");

            setAnimeList((await (await fetch("data/animeList.json")).json())
                .sort((a: Anime, b: Anime) => collator.compare(a.name, b.name)));
            const questions : Array<Question> = (await (await fetch("data/questions.json")).json())
                .sort((a: Question, b: Question) => a.id > b.id ? -1 : 1);
            const questionStates : Array<QuestionState> = await db.QuestionStates.toArray();


            const games =
                questions.reduce(
                    (acc, current) => {
                        acc.push(questionToGameData(current));
                        return acc;
                    },
                    [] as Array<GameData>
                );
            setGames(games);

            const lastGameId = questions[0].id;
            if(!questionStates.find(state => state.questionId === lastGameId && state.solved)) {
                selectGame(games.find(game => game.question.id = lastGameId));
            }

            function questionToGameData(question : Question) {
                return {
                    question: question,
                    questionState: questionStates.find(
                        state => state.questionId === question.id
                    ),
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (selectedGame) {
            setSelectedGame(games.find(game => game.question.id === selectedGame.question.id));
        }
    }, [games]);

    function selectGame(game: GameData | undefined) {
        setSelectedGame(game);
    }

    function showGamesList() {
        setSelectedGame(undefined);
    }

    function changeQuestionState(newState : QuestionState) {
        if (isEqual(newState, selectedGame?.questionState)) {
            return;
        }

        db.QuestionStates.put(newState);

        setGames(games.map(
            game =>
                game.question.id === newState.questionId
                    ? {
                        question: { ...game.question },
                        questionState: newState,
                    }
                    : game
        ));
    }

    const content = selectedGame
        ? <Game
            gameData={selectedGame}
            animeList={animeList}
            goBack={showGamesList}
            changeQuestionState={changeQuestionState}
        />
        : <GameList
            games={games}
            selectGame={selectGame}
        />;

    return <>
        <Header
            backButtonAction={selectedGame && showGamesList}
        />
            {content}
        <div></div>
    </>
}
