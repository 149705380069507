import { nanoid } from "nanoid";
import { ProgressSquaresProps } from "../../ts/types";
import "./ProgressSquares.scss";

export default function ProgressSquares({redCount, greenCount, greyCount} : ProgressSquaresProps) {
    const squares = [];

    const squareClasses = [];

    for (let i = 0; i < redCount; i++) {
        squares.push(<div key={nanoid(10)}>🟥</div>);
        squareClasses.push("progress-square--red");
    }

    for (let i = 0; i < greenCount; i++) {
        squares.push(<div key={nanoid(10)}>🟩</div>);
        squareClasses.push("progress-square--green");
    }

    for (let i = 0; i < greyCount; i++) {
        squares.push(<div key={nanoid(10)}>⬜</div>);
        squareClasses.push("progress-square--grey");
    }

    return <div className="progress-squares">
        {squareClasses.map((squareClass) => <div key={nanoid(10)} className={`progress-square ${squareClass}`}></div>)}
    </div>
}
