import { GamesListProps } from "../../ts/types";
import { nanoid } from "nanoid";
import "./GameList.scss";
import ProgressSquares from "../ProgressSquares/ProgressSquares";

export default function GameList({
    games,
    selectGame,
} : GamesListProps) {
    return (
        <div
            className="game-list__container"
        >
            <h2 className="game-list__title">Pick a day:</h2>
            <ul
                className="game-list"
            >
                {
                    games.map(game =>
                        {
                            const isSolved = game.questionState?.solved;
                            const hintsCount = game.question.hints.length;
                            const guessesCount = game.questionState?.guessIds.length || 0;
                            const statusDefaultClass = "game-list__day-status";
                            const statusClasses = [statusDefaultClass];

                            let statusText;

                            if (isSolved) {
                                statusText = "Solved";
                                statusClasses.push(statusDefaultClass.concat("--correct"));
                            } else {
                                if (guessesCount === hintsCount) {
                                    statusText = "Failed";
                                    statusClasses.push(statusDefaultClass.concat("--failed"));
                                } else {
                                    statusText = "Not completed";
                                }
                            }

                            return (
                                <li
                                    key={nanoid(10)}
                                    onClick={() => selectGame(game)}
                                    className="game-list__element"
                                >
                                    <span className="game-list__day-title">
                                        {`#${game.question.id}`}
                                    </span>
                                    <div className="game-list__day-progress">
                                        {
                                            isSolved
                                                ? ProgressSquares({
                                                    redCount : guessesCount - 1,
                                                    greenCount : hintsCount - guessesCount + 1,
                                                    greyCount : 0
                                                })
                                                : ProgressSquares({
                                                    redCount : guessesCount,
                                                    greenCount : 0,
                                                    greyCount : hintsCount - guessesCount
                                                })
                                        }
                                    </div>
                                    <span className={statusClasses.join(' ')}>
                                        {statusText}
                                    </span>
                                </li>
                            )
                        }
                    )
                }
            </ul>
        </div>
    )
}
