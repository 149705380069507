import { useEffect, useState } from "react";
import { Anime, Hint, GameProps } from "../../ts/types";
import Button from "../Button/Button";
import AnswerInput from "../AnswerInput/AnswerInput";
import GuessList from "../GuessList/GuessList";
import AnswerResult from "../AnswerResult/AnswerResult";
import "./Game.scss";
import config from "../../config.json";
import HintImage from "../HintImage/HintImage";
import ShareButton from "../ShareButton/ShareButton";

// хинты пока оставляем ручными, нужно продумать лучше
// надо обновить базу, уменьшить ее размер, убрав ненужные поля, посмотреть какой новый формат отдает апиха

export default function Game({
    gameData,
    animeList,
    goBack,
    changeQuestionState,
} : GameProps) {
    const hints : Array<Hint> = gameData.question.hints;
    const imagesDirPath = config.IMAGE_DIR!.concat(gameData.question.id.toString(), '/');
    const correctAnswer = gameData.question.answerId;

    const [isSolved, setIsSolved] = useState(gameData.questionState?.solved || false);
    const [guessList, setGuessList] = useState<Array<Anime>>(idsToAnimes(gameData.questionState?.guessIds || []));
    const guessesCount = guessList.length;

    const gameIsOver = isSolved || (guessesCount === hints.length && hints.length > 0);

    const [currentHintId, setCurrentHintId] = useState(
        gameIsOver
            ? 1
            : (guessesCount + 1)
    );
    const currentHint = hints.find(hint => hint.id === currentHintId);

    useEffect(
        () => {
            changeQuestionState({
                questionId: gameData.question.id,
                solved: isSolved,
                guessIds: guessList.map(anime => anime.id),
            })},
        [isSolved, guessList]
    );

    return(
        <div className="game-container">
            <div className="game__hint-image-outer-wrapper">
                {
                    hints.length > 0 &&
                        <HintImage
                            src={imagesDirPath + currentHint?.imageName}
                            hintText={currentHint?.text}
                        />
                }
            </div>
            <div className="game_content-area">
                <div className="select-hint-buttons">
                    {hints.map(
                        ({ id }) =>
                            <div
                                className="select-hint-button"
                                key={id}
                            >
                                <Button
                                    disabled={id > guessesCount + 1 && !isSolved}
                                    onClick={() => setCurrentHintId(id)}
                                    active={currentHintId === id}
                                    text={id.toString()}
                                    extraStyle={
                                        isSolved && id >= guessesCount
                                            ? "positive"
                                            : id <= guessesCount
                                                ? "negative"
                                                : undefined
                                    }
                                />
                            </div>
                    )}
                    {!gameIsOver &&
                        <div className="game_skip-button">
                            <Button
                                disabled={false}
                                onClick={skipAnswer}
                                active={false}
                                text={"Skip"}
                                extraStyle="negative"
                            />
                        </div>
                    }
                </div>
                {
                    gameIsOver
                        ? <AnswerResult
                            success={isSolved}
                            correctAnswer={getAnimeName(correctAnswer)}
                        />
                        : <AnswerInput
                            onSubmit={submitAnswer}
                            optionsList={animeList}
                        />
                }
                {guessesCount > 0 &&
                    <div className="guess-list">
                        <GuessList
                            guesses={guessList}
                            correctAnswerId={correctAnswer}
                        />
                    </div>
                }
                {gameIsOver &&
                    <div
                        className="completed-game__buttons"
                    >
                        <Button
                            disabled={false}
                            active={false}
                            text={"Play more"}
                            onClick={goBack}
                            extraStyle={"primary"}
                            icon={"back"}
                        />
                        <ShareButton
                            gameData={gameData}
                        />
                    </div>
                }
            </div>
        </div>
    );

    function onCorrectAnswer() {
        setIsSolved(true);
    }

    function onWrongAnswer() {
        setCurrentHintId(Math.min(guessesCount + 2, hints.length));
    }

    function addGuess(anime: Anime) {
        const newGuessList = guessList.slice();
        newGuessList.push(anime);

        setGuessList(newGuessList);

        return newGuessList;
    }

    function idsToAnimes(ids : Array<number>) {
        return ids.reduce(
            (acc, current) =>
                {
                    acc.push({
                        id: current,
                        name: getAnimeName(current),
                    });
                    return acc;
                },
                [] as Array<Anime>
        )
    }

    function getAnimeName(animeId: number) {
        return animeId === 0
            ? "Skipped"
            : animeList.find(anime => anime.id === animeId)?.name || "Unknown anime";
    }

    function submitAnswer(guess: Anime) {
        addGuess(guess);

        if (guess.id === correctAnswer) {
            onCorrectAnswer();
        } else {
            onWrongAnswer();
        }
    }

    function skipAnswer() {
        submitAnswer({
            id: 0,
            name: "Skipped",
        });
    }
}
